/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'ui-checks-grid': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 10h3a1 1 0 011 1v3a1 1 0 01-1 1H2a1 1 0 01-1-1v-3a1 1 0 011-1m9-9h3a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1V2a1 1 0 011-1m0 9a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1zm0-10a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2V2a2 2 0 00-2-2zM2 9a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2v-3a2 2 0 00-2-2zm7 2a2 2 0 012-2h3a2 2 0 012 2v3a2 2 0 01-2 2h-3a2 2 0 01-2-2zM0 2a2 2 0 012-2h3a2 2 0 012 2v3a2 2 0 01-2 2H2a2 2 0 01-2-2zm5.354.854a.5.5 0 10-.708-.708L3 3.793l-.646-.647a.5.5 0 10-.708.708l1 1a.5.5 0 00.708 0z"/>',
    },
});
